import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Template6 from './Template6';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/login-here" element={<Template6 />} />
        {/* Add a wildcard route to redirect any other route to Template6 */}
        <Route path="*" element={<Navigate to="/login-here" />} />
      </Routes>
    </Router>
  );
}

export default App;